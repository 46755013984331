$(document).ready( function(){
  $('.location-type-selection-field').change(function(){
    var selected_location_type_id = parseInt( $(this).find(":selected").val() );
    updateDisplayForSelectedLocationType( selected_location_type_id );
  });

  var selected_location_type_id = parseInt( $('.location-type-selection-field').find(":selected").val() );
  updateDisplayForSelectedLocationType( selected_location_type_id );
});

function updateDisplayForSelectedLocationType( selected_location_type_id ){
  if( !isNaN( selected_location_type_id ) ){
    if( jQuery.inArray( selected_location_type_id, senior_home_location_types ) != -1 ){
      // alert( "senior home" );
      $(".section-requires-location-type-senior-home").show();
    }
    else{
      // alert( "not senior home" );
      $(".section-requires-location-type-senior-home").hide();
    }
  }
}
