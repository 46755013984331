$(document).ready( function(){
  $('.journal-entries .journal-entry .row.journal-entry-header').click(function(){
    var addClass = !$(this).parent().hasClass('active');
    $('.journal-entries .journal-entry').removeClass('active').children('.journal-entry-body').each(function(index, el) {
      this.style.maxHeight = 0;
    });
    if (addClass) {
      activateJournalEntry($(this).parent());
    }
  });
  var journalEntryId = window.location.hash.replace('journal-entry-', 'journal_entry_');
  activateJournalEntry(journalEntryId)
});

function activateJournalEntry(el) {
  $(el).addClass('active');
  elements = $('.journal-entry-body', $(el)).get();
  if (elements.length) {
    journalEntryBodyElement = elements[0];
    journalEntryBodyElement.style.maxHeight = journalEntryBodyElement.scrollHeight + "px";
  }
}
