$(document).ready( function(){
  $('button:submit:not([data-skip-disable])').click(function(){
    if (!window.location.pathname.startsWith('/admin')) {
      $("span.action", this).html('Submitting, please wait...');
      $(this).prop("disabled", true);
      var button = this;
      $(this).closest('form').submit();
      window.setTimeout(function() {
        $(button).prop("disabled", false);
      }, 500);
    }
  });

  $('form[method=get]').children('button:submit, input:submit').click(function(){
    $(this).closest('form').submit();
    var button = this;
    window.setTimeout(function() {
      console.log("timeout");
      $(button).closest('form').children('button:submit, input:submit').each(function(){
        $(this).prop("disabled", false);
      })
    }, 500);
  });
});
