// https://snazzymaps.com/style/151/ultra-light-with-labels
var google_map_style = [
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e9e9e9"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 29
      },
      {
        "weight": 0.2
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 18
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dedede"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "saturation": 36
      },
      {
        "color": "#333333"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f2f2f2"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  }
]

import { Loader } from "@googlemaps/js-api-loader"

const loader = new Loader({
  apiKey: "AIzaSyByEarnrlKIGESwLnspZvRH8JObHRFF9F0",
  version: "weekly",
  // ...additionalOptions,
});

var infoWindow = null;

loader.load().then(() => {
  if(document.getElementById('chapter_map')) {
    var united_states_center = {lat: 39.8333333, lng: -98.585522};
    var map = new google.maps.Map(document.getElementById('chapter_map'), {
      zoom: 4,
      center: united_states_center,
      styles: google_map_style
    });
    var chst = "d_map_spin";
    var chld = "0.5|0|e9599c|14|_|%E2%80%A2";
    var pinColor = "e9599c";
    var pinImage = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=" + chst + "&chld=" + chld,
        new google.maps.Size(21, 34),
        new google.maps.Point(0,0),
        new google.maps.Point(10, 34));
    var pinShadow = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_shadow",
        new google.maps.Size(40, 37),
        new google.maps.Point(0, 0),
        new google.maps.Point(12, 35));

    $.ajax({
      dataType: "json",
      url: '/chapters.json',
      success: function(data) {
        $.each( data, function( index, chapter ) {
          var marker = new google.maps.Marker({
            position: {lat: chapter.address.latitude, lng: chapter.address.longitude},
            map: map,
            icon: pinImage,
            shadow: pinShadow
          });
          marker.addListener('click', function(){
            if (infoWindow == null) {
              infoWindow = new google.maps.InfoWindow({
              });
            }
            infoWindow.close();
            var content = $('<div>' +
                '<h5>' +
                  '<a href="/chapters/' + chapter.id + '">' +
                    chapter.name +
                  '</a>' +
                '</h5>' +
                '<p>' +
                  chapter.address.city + ', ' + chapter.address.state +
                '</p>' +
              '</div>');
            infoWindow.setContent(content.get()[0]);
            infoWindow.open(map, marker);
          });
        });
      }
    });
  }
});
